@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bg-color: #191a22;
  --swiper-theme-color: #088FF6;
  --primary-color: #f42656;
  --content-width: 1150px;
  --link-color: #24DFBA;
  --font-weight-heading: 900;
  --font-size-heading-mobile: 500;
  --font-weight-light: 300;
  --font-size-light-mobile: 165;
  --gradient-color1: #088FF6;
  --gradient-color2: #0FEBBF;
  --input-background-color: #EDF1F4;
  --input-border-color: #E0E0E0;
  --input-text-color: #000000;
  --footer-background-color: #272C38;
  --mui-fontFamily-display: "Montserrat", sans-serif !important;
  --mui-fontFamily-body: "Montserrat", sans-serif !important;
}

.marked {
  background: linear-gradient(to right, var(--gradient-color1), var(--gradient-color2));
  background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.button--round {
  background-color: white !important;
  background-image: linear-gradient(to right, var(--gradient-color1), var(--gradient-color2)) !important;
  color: white !important;
  transition: all 0.2s !important;
  font-size: 1.25rem !important;
  height: 3.5rem !important;
  border-radius: 3rem !important;
  padding: 1rem 2.5rem !important;
  display: flex !important;
  gap: 1rem !important;
  align-items: center !important;
  font-weight: var(--font-weight-light) !important;
  letter-spacing: 0.1rem;
}

@media (max-width: 768px) {
  .button--round {
    font-size: 1rem !important;
    height: 2.5rem !important;
    border-radius: 2rem !important;
    padding: 0.5rem 1.5rem !important;
  }
}

.button--round:hover {
  transform: scale(1.1) !important;
}
